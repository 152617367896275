<template>
  <div class="w-full flex flex-col items-center bg-surface">
    <div class="w-full bg-surface px-1 sm:!px-[52px] border-b border-b-borderColor border-opacity-100">
      <svn-pro-breadcrumbs :items="breadcrumbItems" />
    </div>

    <div class="w-full flex flex-col gap-4 md:!gap-6 desktop:!gap-8 md:!py-6 desktop:!py-10">
      <div class="w-full flex flex-col md:!flex-row px-5 md:!items-center md:!justify-between md:!px-0 mx-auto md:!max-w-[672px] lg:!max-w-[928px] xl:!max-w-[1137px]">
        <svn-pro-title h5 medium>
          {{ campaign?.title }}
        </svn-pro-title>
  
        <svn-pro-text subtitle-medium regular>
          {{ $t("Deadline") }} : {{ campaign?.deadline }}
        </svn-pro-text>
      </div>
  
      <div class="w-full md:!max-w-[672px] lg:!max-w-[928px] xl:!max-w-[1136px] mx-auto flex px-5 flex-col justify-center">
        <BktSurveyAnswers
          v-if="campaign"
          :campaign="campaign"
          :is-trashed="campaign?.status === 'trashed'"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useCampaignStore } from "@/store/campaign.js";
import { onMounted, onUnmounted, computed } from "vue";
import BktSurveyAnswers from "@/components/BktSurveyAnswers.vue";

const { campaign } = storeToRefs(useCampaignStore());
const { fetchCampaign, resetStates } = useCampaignStore();

const route = useRoute();

onMounted(() => {
  fetchCampaign(route?.params?.id);
});

onUnmounted(() => {
  resetStates();
});

const breadcrumbItems = computed(() => {
  return [
    {
      title: 'My Team Interviews',
      disabled: false,
      href: '/v2/interviews/my_team_interviews',
    },
    {
      title: campaign?.value?.title,
      disabled: false,
      href: '',
    },
  ]
});
</script>